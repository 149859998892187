import React from 'react';
import './App.css';
import styled from 'styled-components';

import Img from '../src/assets/images/TraveLand.jpg'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Wrapper src={Img} alt="" />
        <h2>TraveLand • თრეველენდი</h2>
        <a href="tel:571 09 88 55">571 09 88 55</a>
        <a href="mailto: teamtraveland@gmail.com">teamtraveland@gmail.com</a>
        <br />
        <h2>Cooming Soon...</h2>
      </header>
    </div>
  );
}

const Wrapper = styled.img`
  width: 400px;
  overflow: hidden;
  object-fit: cover;
`;

export default App;
